import React, { useEffect, useState } from "react";
import Layout from "./components/layout";
import { Toaster } from "react-hot-toast";
import Classes from "./App.module.css";
import loader from "./loader.gif";
import { FaRetweet } from "react-icons/fa";

if (window.location.search.split("=")[0] === "?token") {
  sessionStorage.setItem("a_tk", window.location.search.split("=")[1]);
} else if (!sessionStorage.getItem("a_tk")) {
  window.location.replace("https://caerry.netlify.app/");
}

function App() {
  // const url = "http://localhost:4009/api/v1/";
  const url = "https://api-caerry-v.onrender.com/api/v1/";
  // const homeUrl = "http://127.0.0.1:5500/";
  const homeUrl = "https://caerry.netlify.app/";
  const [token, setToken] = useState(sessionStorage.getItem("a_tk") || "");
  const [notificationCount, setNotificationCount] = useState(0);
  const [codeID, setCodeID] = useState(null);
  const [user, setUser] = useState(
    JSON.parse(sessionStorage.getItem("user")) || {}
  );
  // const [inputs, setInputs] = useState({});
  const [loader, setLoader] = useState(false);
  const [err, setErr] = useState(false);

  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  const setTokenHandler = (_token) => {
    setToken(_token);
    sessionStorage.setItem("a_tk", _token);
  };

  const setUserHandler = (_user) => {
    setUser(_user);
    sessionStorage.setItem("user", JSON.stringify(_user));
  };

  const logoutHandler = () => {
    sessionStorage.clear();
    setToken("");
    setUser({});
    window.location.replace(homeUrl);
    // window.location.reload();
  };

  const getTrackingDetails = async () => {
    if (!token) return;
    // if (loader) return;
    setLoader(true);
    setErr(false);
    try {
      const getD = await fetch(`${url}shipment/get/${token}`);
      const parsedJson = await getD.json();

      if (parsedJson.e) {
        setErr(true);
        return;
      }

      setErr(false);
      setLoader(false);
      // console.log("parsedJson ==> ", parsedJson);
      sessionStorage.setItem("a_code", parsedJson?.d?._id);
      setCodeID(parsedJson?.d?._id);
    } catch (error) {
      setLoader(true);
      console.log("error ==> ", error);
      setErr(true);
    }
  };

  useEffect(() => {
    if (token.length > 1) {
      getTrackingDetails();
    }
  }, [token]);

  return (
    <MainContext.Provider
      value={{
        url,
        homeUrl,
        token,
        user,
        setToken: setTokenHandler,
        setUser: setUserHandler,
        logout: logoutHandler,
        isNotificationOpen,
        codeID,
        toogleIsNotificationOpen: () =>
          setIsNotificationOpen(!isNotificationOpen),
        notificationCount,
        setNotificationCount: (_payload) => setNotificationCount(_payload),
      }}
    >
      {loader && (
        <div className={Classes.loadingModal}>
          <div className={Classes.insideModal}>
            {err ? (
              <>
                <div>Network request failed</div>
                <div
                  onClick={() => getTrackingDetails()}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    cursor: "pointer",
                    alignItems: "center",
                    marginTop: "6px",
                  }}
                >
                  <strong>Click to retry</strong>
                  <FaRetweet size={20} />
                </div>
              </>
            ) : (
              <>
                {" "}
                <div className={Classes.lds_facebook}>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <h3>Setting up content, please wait...</h3>
              </>
            )}
          </div>
        </div>
      )}

      <Layout />
      <Toaster
        position="bottom-left"
        toastOptions={{
          style: {
            background: "#fc0",
            borderLeft: "5px solid #000",
            fontSize: "14px",
            color: "#000",
          },
        }}
      />
    </MainContext.Provider>
  );
}

export default App;

export const MainContext = React.createContext({
  url: null,
  homeUrl: null,
  sideDrawer: null,
  setSideDrawer: () => {},
  token: null,
  setToken: () => {},
  logout: () => {},
  setUser: () => {},
  user: null,
  isNotificationOpen: null,
  toogleIsNotificationOpen: () => {},
  codeID: null,
  setCodeID: () => {},
  notificationCount: null,
  setNotificationCount: () => {},
});

// http://localhost:3000/dashboard?token=cv_r095523
