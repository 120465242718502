import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { MainContext } from "../App";
import NavLogo from "../assets/logo.png";
import moment from "moment";
import Classes from "../App.module.css";

const Nav = () => {
  const CTX = useContext(MainContext);
  const [isDropDown, setIsDropDown] = useState(false);
  const [IsNavbarCollapsed, setIsNavbarCollapsed] = useState(false);
  const [inputs, setInputs] = useState("");
  const [loader, setLoader] = useState(false);
  const [err, setErr] = useState(false);

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);
      setErr(false);

      const sendDetails = await fetch(
        // `https://randomthree.dscryptoworld.com/api/v1/shipment/get/${tracking_number_id.value}`
        `${CTX.url}shipment/get/${inputs}`
      );
      const parseJson = await sendDetails.json();
      console.log("parseJson HERE! ===> ", parseJson);
      if (parseJson.e) {
        setErr(true);
        setLoader(false);
        console.log("parseJson.e ==>> ", parseJson.e);
        return;
      } else {
        setErr(false);
        window.location.replace(
          `https://dash-caerry.pages.dev/dashboard?token=${inputs}`
        );
        // window.location.replace(
        //   `http://localhost:3000/dashboard?token=${inputs}`
        // );
      }

      // console.log("inputs ==>> ", inputs);
    } catch (error) {
      setLoader(false);
      console.log("error ==> ", error);
      alert("Check your internet connection");
    }
  };

  useEffect(() => {
    window.addEventListener("click", (e) => {
      setIsDropDown(false);
    });

    // return () => {
    //   window.removeEventListener("click")
    // }
  }, []);

  return (
    <nav className="navbar navbar-default navbar-fixed-top">
      {loader && (
        <div className={Classes.loadingModal}>
          <div className={Classes.insideModal}>
            <div className={Classes.lds_facebook}>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <h3>Setting up content, please wait...</h3>
          </div>
        </div>
      )}

      <div className="container-fluid">
        <div className="navbar-header">
          <button
            type="button"
            onClick={() => setIsNavbarCollapsed(!IsNavbarCollapsed)}
            className={
              IsNavbarCollapsed ? "navbar-toggle" : "navbar-toggle collapsed"
            }
            data-toggle="collapse"
            data-target="#navbar"
            aria-expanded="false"
            aria-controls="navbar"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          {/* <a className="navbar-brand" href="/dashboard">
            <span className="sr-only">Sendle</span>
          </a> */}
          <Link to="/dashboard">
            <img src={NavLogo} alt="caerry" />
          </Link>
        </div>
        <form
          onSubmit={onSubmitHandler}
          className="navbar-form navbar-left search-form"
          role="search"
        >
          <div
            data-react-className="Dashboard.DropdownOrderSearch"
            data-react-props='{"value":null,"placeholder":"Search orders, contacts or history","url":"/dashboard/search?q=QUERY"}'
            data-react-cache-id="Dashboard.DropdownOrderSearch-0"
          >
            <span
              className="twitter-typeahead"
              style={{ position: "relative", display: "inline-block" }}
            >
              {/* <input
                type="search"
                className="form-control tt-hint"
                value=""
                readonly=""
                autocomplete="off"
                spellcheck="false"
                tabindex="-1"
                style={{
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  borderColor: "transparent",
                  boxShadow: "none",
                  opacity: "1",
                  background:
                  "none 0% 0% / auto repeat scroll padding-box border-box rgb(255, 255, 255)",
                }}
                dir="ltr"
              /> */}
              <input
                type="search"
                name="search"
                className="form-control tt-input"
                placeholder="Search tracking id . . ."
                value={inputs}
                onChange={(e) => setInputs(e.target.value)}
                autocomplete="off"
                spellcheck="false"
                dir="auto"
                style={{
                  position: "relative",
                  verticalAlign: "top",
                  border: err ? "2px solid #ff5565" : "1px solid #82909b",
                  backgroundColor: "transparent",
                }}
              />
              <pre
                aria-hidden="true"
                style={{
                  position: "absolute",
                  visibility: "hidden",
                  whiteSpace: "pre",
                  fontFamily:
                    "Open Sans Helvetica Neue Helvetica Arial sans-serif",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontVariant: "normal",
                  fontWeight: "400",
                  wordSpacing: "0px",
                  letterSpacing: "0px",
                  textIndent: "0px",
                  textRendering: "auto",
                  textTransform: "none",
                }}
              ></pre>
              <div
                className="tt-menu"
                style={{
                  position: "absolute",
                  top: "100%",
                  left: "0px",
                  zIndex: "100",
                  display: "none",
                }}
              >
                <div className="tt-dataset-orders tt-dataset-orders-0"></div>
              </div>
            </span>
          </div>
        </form>

        <div className="nav-items">
          <div className="nav-items__center-items">
            <div
              data-react-className="Dashboard.NotificationButton"
              data-react-props="{}"
              data-react-cache-id="Dashboard.NotificationButton-0"
            >
              <div className="notifications" style={{ marginLeft: "20px" }}>
                <button
                  onClick={() => CTX.toogleIsNotificationOpen()}
                  className="notification-button "
                  style={{ position: "relative" }}
                >
                  {CTX.notificationCount > 0 && <div
                    style={{
                      height: "12px",
                      width:  "12px",
                      backgroundColor: "red",
                      color: "#fff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: '9px',
                      fontWeight: "bold",
                      position: "absolute",
                      top: "-4px",
                      right: "-4px",
borderRadius: "50%",
                    }}
                  >
                    <div style={{paddingTop: "1px"}}> {CTX.notificationCount}</div>
                  </div>}
                  <div className="notification-button__badge"></div>
                  <svg
                    className="notification-button__icon"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 15 18"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M6.17 15.5a1.4 1.4 0 002.67 0M7.5 3V1.5"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M7.5 3a5 5 0 015 5c0 4.7 1 5.5 1 5.5h-12s1-1.28 1-5.5a5 5 0 015-5z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>

            <div
              data-react-className="Dashboard.LocaleSwitcher"
              data-react-props='{"currentLocale":"en-us","senderCountry":"US"}'
              data-react-cache-id="Dashboard.LocaleSwitcher-0"
            ></div>
          </div>
          <div
            className={
              IsNavbarCollapsed
                ? "collapse navbar-collapse in"
                : "collapse navbar-collapse"
            }
            id="navbar"
          >
            <div className="visible-xs">
              <ul className="nav nav-sidebar">
                <li onClick={() => setIsNavbarCollapsed(false)}>
                  <NavLink
                    className="icon-link icon-link-dashboard"
                    to="/dashboard"
                  >
                    Dashboard
                  </NavLink>
                </li>
                <li onClick={() => setIsNavbarCollapsed(false)}>
                  <NavLink
                    className="icon-link icon-link-sending"
                    to={`/account/send/${
                      Math.random().toString().split(".")[1]
                    }`}
                  >
                    Send Parcel
                  </NavLink>{" "}
                </li>
                <li onClick={() => setIsNavbarCollapsed(false)}>
                  <NavLink
                    className="icon-link icon-link-receiving"
                    to={`/account/${
                      Math.random().toString().split(".")[1]
                    }/received_orders`}
                  >
                    View Item
                  </NavLink>{" "}
                </li>
                {/* <li onClick={() => setIsNavbarCollapsed(false)}>
                  <NavLink
                    className="icon-link icon-link-connect"
                    data-turbolinks="false"
                    to={`/${sessionStorage.getItem("a_code")}/locate/item/${CTX.token}`}
          >
            Locate Item
                  </NavLink>{" "}
                </li> */}
              </ul>

              <ul className="nav nav-sidebar">
                {/* <li>
                  <NavLink
                    className="icon-link icon-link-settings"
                    to="/account/:userID/settings"
                  >
                    Settings
                  </NavLink>{" "}
                </li>
                <li>
                  <NavLink
                    className="icon-link icon-link-billing"
                    to={`/account/${
                      Math.random().toString().split(".")[1]
                    }/billing`}
                  >
                    Billing
                  </NavLink>{" "}
                </li> */}
                {/* <li onClick={() => setIsNavbarCollapsed(false)}>
                  <NavLink
                    className="icon-link icon-link-support"
                    to="/:userId/support"
                  >
                    Support
                  </NavLink>
                </li> */}
              </ul>

              <ul className="nav nav-footer hidden-xs">
                <li className="utility-links">
                  <a href="/dashboard"></a>
                  <a target="_blank" href={`${CTX.homeUrl}/terms.html"`}>
                    Terms
                  </a>
                  <span className="copyright">
                    © Caerry {moment().format("YYYY")}
                  </span>
                </li>
              </ul>
            </div>
            <ul className="nav navbar-nav navbar-right">
              <li
                className={isDropDown ? "dropdown open" : "dropdown"}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsDropDown(!isDropDown);
                }}
              >
                <a
                  href="#"
                  className="dropdown-toggle profile-link"
                  data-toggle="dropdown"
                  role="button"
                  aria-expanded="false"
                  data-hj-masked=""
                >
                  <div className="profile-image">
                    <img
                      alt=""
                      width="30"
                      height="30"
                      src={require("../assets/favIcon.png")}
                    />
                  </div>
                  {CTX.token}
                  <span className="caret"></span>
                </a>

                <ul className="dropdown-menu" role="menu">
                  <li>
                    <a
                      rel="nofollow"
                      data-method="delete"
                      href="#"
                      onClick={(e) => {
                        e.stopPropagation();
                        CTX.logout();
                      }}
                    >
                      Logout
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
