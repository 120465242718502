import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import moment from "moment";
import Classes from "./sidebar.module.css";
import { MainContext } from "../App";

const Sidebar = () => {
  const CTX = useContext(MainContext);

  return (
    <div className="sidebar">
      <ul className="nav nav-sidebar">
        <li>
          <NavLink to="/dashboard" className="icon-link icon-link-dashboard">
            Dashboard
          </NavLink>
        </li>
        <li>
          <NavLink
            className="icon-link icon-link-sending"
            to={`/account/send/${Math.random().toString().split(".")[1]}`}
          >
            Send Parcel
          </NavLink>
        </li>
        <li>
          <NavLink
            className="icon-link icon-link-receiving"
            to={`/account/${
              Math.random().toString().split(".")[1]
            }/received_orders`}
          >
            View Item
          </NavLink>
        </li>
        {/* <li>
          <NavLink
            className="icon-link icon-link-connect"
            data-turbolinks="false"
            to={`/${sessionStorage.getItem("a_code")}/locate/item/${CTX.token}`}
          >
            Locate Item
          </NavLink>
        </li> */}
      </ul>

      <ul className="nav nav-sidebar">
        <li >
          <a
          style={{position: "relative"}}
            className="icon-link icon-link-support"
            herf="#"
            onClick={() => CTX.toogleIsNotificationOpen()}
          >
            Notification
          {CTX.notificationCount > 0 && <div
                    style={{
                      height: "12px",
                      width:  "12px",
                      backgroundColor: "red",
                      color: "#fff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: '9px',
                      fontWeight: "bold",
                      position: "absolute",
                      top: "17px",
                      right: "12px",
borderRadius: "50%",
                    }}
                  >
                    <div > {CTX.notificationCount}</div>
                  </div>}
          </a>

        </li>
        <li>
          <a
            onClick={() => CTX.logout()}
            href="#"
            className="icon-link icon-link-settings"
          >
            Logout
          </a>
        </li>
        {/* <li>
          <NavLink className="icon-link icon-link-billing" to={`/account/${Math.random().toString().split(".")[1]}/billing`}>
            Billing
          </NavLink>
        </li> */}
      </ul>

      <ul className="nav nav-footer hidden-xs">
        <li className="utility-links">
          <a target="_blank" href={CTX.homeUrl + "terms.html"}>
            Terms
          </a>
          <span className="copyright">© Caerry {moment().format("YYYY")}</span>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
