import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "../App";
import {AiFillDelete} from 'react-icons/ai'

const Notification = () => {
  const CTX = useContext(MainContext);
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);
  const [toggleAllNot, setToggleAllNot] = useState(false);
  const [listNots, setListNots] = useState(Array(0).fill("s"));

  const getConsignmentNotification = async () => {
    // console.log("notifiicationID HERE!! ===>> ", CTX.notificationId);

    if (loading) return;
    setLoading(true);
    try {
      // console.log("inputs HERE! ===> ", inputs);
      const postinng = await fetch(
        `${CTX.url}notification/get/${sessionStorage.getItem("a_code")}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const jsoned = await postinng.json();
      if (jsoned.e) {
        alert("Please try again, an error occurred");
        return;
      }

      setListNots(jsoned.d.reverse());
      CTX.setNotificationCount(jsoned?.d?.length)
      setErr(false);
      setLoading(false);
    } catch (error) {
      alert("Please try again, an error occurred");
      setLoading(false);
      setErr(true);
      console.log(error);
    }
  };

  useEffect(() => {
    if(sessionStorage.getItem("a_code")) {
      getConsignmentNotification();
    }

  }, [CTX.isNotificationOpen]);






  const deleteNotificationHandler = async (id, e) => {
    if (loading) return;
    setLoading(true);
    try {
      const postinng = await fetch(`${CTX.url}notification/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          note: text,
        }),
      });
      const jsoned = await postinng.json();
      if (jsoned.e) {
        alert("Please try again, an error occurred");
        return;
      }
      getConsignmentNotification();
      setErr(false);
      setLoading(false);
    } catch (error) {
      alert("Please try again, an error occurred");
      setLoading(false);
      setErr(true);
      console.log(error);
    }
  };








  // mapping list of notifications
  const mappedNotifications = listNots.map((v, i) => (
    <div
      style={{
        position: "relative",
        padding: ".5em",
        marginBottom: ".4em",
        border: "0.0625rem solid #b4bfc9",
      }}
      key={i}
    >
      <div>{v.note}</div>

      <AiFillDelete
        size={19}
        onClick={deleteNotificationHandler.bind(this, v._id)}
        color="#d00"
        style={{
          cursor: "pointer",
          position: "absolute",
          top: "7px",
          right: "7px",
        }}
      />
    </div>
  ));



  return (
    <div id="support">
      <div
        id="support-modal"
        className={
          CTX.isNotificationOpen ? "modal fadeInUp in" : "modal fadeInUp"
        }
        tabindex="-1"
        role="dialog"
        data-preloaded-event-type=""
        style={{ display: CTX.isNotificationOpen ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div id="modal-header-wrapper">
              <nav className="modal-header">
                <div id="support-modal-nav-tabs" role="tablist">
                  <a
                    className="modal-title active"
                    id="support-nav-notifications-tab"
                    data-toggle="tab"
                    href="#support-nav-notifications"
                    role="tab"
                    aria-controls="support-nav-notifications"
                    aria-selected="false"
                  >
                    Notifications
                    <span className="unread-badge"></span>
                  </a>
                  <span
                    className="close"
                    data-dismiss="modal"
                    href="#"
                    onClick={() => CTX.toogleIsNotificationOpen()}
                  >
                    +
                  </span>
                </div>
              </nav>
            </div>
            <div id="scroll-target"></div>
            <div className="tab-content">
              <div
                className="tab-pane fade active in"
                id="support-nav-notifications"
                role="tabpanel"
                aria-labelledby="support-nav-notifications-tab"
              >
                <div
                  data-react-className="Dashboard.NotificationFeed"
                  data-react-props='{"token":"eyJhbGciOiJIUzUxMiJ9.eyJleHRlcm5hbF9hY2NvdW50X2lkIjoiYWYxMjEyNTItMGI0My00NDZmLWI1ZTctMzM5NzM0NWRjOTg1IiwiZXhwIjoxNjc4ODc0NDA3LCJpYXQiOjE2Nzg4NzMyMDd9.0H_4z3OFs8d57llOvP4eoX2LNAeqwEw84-UTaKvCj2B5x5xo5dwK-NhIR9Qi5odyEGEGVjDfHYXjQLebIv8yig","url":"https://sendle-hermes.herokuapp.com","tokenRefreshInterval":20}'
                  data-react-cache-id="Dashboard.NotificationFeed-0"
                >
                  <div>
                    {/* <div className="notification-feed__controls">
                      <button>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 17 11"
                          height=".85em"
                          className="sds-u-m_right--small"
                          aria-hidden="true"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M8.5 1C5.8 1 3 2.8 1.3 4.8c-.4.4-.4 1 0 1.4C3 8.2 5.8 10 8.5 10s5.5-1.9 7.2-3.8c.4-.4.4-1 0-1.4C14 2.8 11.2 1 8.5 1z"
                            clipRule="evenodd"
                          ></path>
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M11 5.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0v0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        View unread only
                      </button>
                      <button disabled="">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 17 12"
                          height=".85em"
                          className="sds-u-m_right--small"
                          aria-hidden="true"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linejoin="round"
                            d="M7.8 10.5L4.4 7a1.5 1.5 0 012.2-2.2l2.1 2.2 4-5.4a1.5 1.5 0 012.5 1.8l-5.1 6.8a1.5 1.5 0 01-2.3.2z"
                            clipRule="evenodd"
                          ></path>
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M7.8 3.7l1.5-2c.4-.5 1-.7 1.6-.6M6 10.8c-.6.2-1.2 0-1.7-.3L1 7a1.5 1.5 0 011.6-2.5"
                          ></path>
                        </svg>
                        Mark all read
                      </button>
                    </div> */}


































                    {mappedNotifications.length < 1 ? (
                    <div className="notification-feed--empty text-center sds-u-p_around--large sds-u-m_right--auto sds-u-m_left--auto">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 191 152"
                        aria-hidden="true"
                        style={{ width: "190px", maxWidth: "100%" }}
                      >
                        <g fill="none" fill-rule="evenodd">
                          <g fill="#F6F9FC">
                            <circle cx="97" cy="76" r="76"></circle>
                            <rect width="36" height="12" y="44" rx="6"></rect>
                            <rect
                              width="36"
                              height="12"
                              x="9"
                              y="70"
                              rx="6"
                            ></rect>
                            <rect
                              width="36"
                              height="12"
                              x="155"
                              y="83"
                              rx="6"
                            ></rect>
                            <rect
                              width="36"
                              height="12"
                              x="137"
                              y="121"
                              rx="6"
                            ></rect>
                          </g>
                          <g
                            stroke="#33424F"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          >
                            <path
                              fill="#B4BFC9"
                              d="M95.2 103.3a10 10 0 0018.8-3.6"
                            ></path>
                            <path d="M88.6 22l-2-10.5"></path>
                            <path
                              fill="#FFF"
                              d="M88.6 22a36 36 0 0142.1 28.5c6.5 33.1 14.6 37.4 14.6 37.4l-84.6 16.4S66 94 60.2 64.2A36 36 0 0188.6 22z"
                            ></path>
                          </g>
                          <path
                            fill="#F6F9FC"
                            d="M160.1 118c-.6 1.3-1 3.3 1.3 3.3s-3 6-4.1 2.6c-1.2-3.4 1.4-5 2.8-5.9zm9.6-21c.4-1.5 1.5-2 2.7-2 1.3 0 0-3 0-3l-5.4
            3.4s1.2 1.7 2.7 1.6zm2.7-16c0 .6-.5 2.2 3.6 2.2 2 0 1 4.4-3.7 2.2-4.7-2.3-1-3.8 0-4.4zM28.7 42c-.5 1.4-1.9 2-3.1 2-1.3 0 0 3 0
            3l5.4-3.4s-.8-1.7-2.3-1.6zm-5.9 17c0-.7-.1-2.7-3.2-2.7-3 0-1.7-5.2 3-2.5 4.7 2.6 1.2 4.5.2 5.2zm122.5 76s1.5-1.6
            4.6-1.5c3.1.2-1.5-2.5-1.5-2.5l-4.4 2 1.3 2zM19 69.6s2.5.2 2.5-2.6c0-2.8 2.5 1.2 2.5 1.2L19.5 72l-.5-2.4zM19 82c1
            0 2.8-.5 3.1 2.7.3 3.2 2.9-.4 2.9-.4L20 80s-1.3 1.1-1 2z"
                          ></path>
                          <path
                            d="M56.2 39L50 36.3m7.6-.1l-1.9-3.5m-.7 9.8l-4 .9"
                            stroke="#F6B139"
                            stroke-linecap="round"
                            stroke-width="1.5"
                          ></path>
                          <g transform="rotate(-10 350.4 -405.1)">
                            <circle cx="4" cy="4" r="4" fill="#33424F"></circle>
                            <path
                              fill="#33424F"
                              d="M21 22c2.4 0 5-3 5-5s-1.2-1.8-5-2c-3.7-.1-5 0-5 2s2.7 5 5 5z"
                            ></path>
                            <circle cx="3" cy="2" r="1" fill="#F7F8F9"></circle>
                            <circle
                              cx="35"
                              cy="4"
                              r="4"
                              fill="#33424F"
                            ></circle>
                            <circle
                              cx="34"
                              cy="2"
                              r="1"
                              fill="#F7F8F9"
                            ></circle>
                          </g>
                          <g transform="translate(125 53)">
                            <ellipse
                              cx="15.7"
                              cy="14"
                              fill="#FFF"
                              rx="13.4"
                              ry="14"
                            ></ellipse>
                            <circle
                              cx="13.4"
                              cy="13.4"
                              r="13.4"
                              fill="#E8FEFF"
                            ></circle>
                            <path
                              fill="#FFF"
                              d="M12 14.7l-1.5-1.6L9 14.7l3 3.2 7-7.3L17.5 9z"
                            ></path>
                          </g>
                          <g stroke="#62CCCC" transform="translate(127 53)">
                            <path
                              fill="#62CCCC"
                              stroke-linejoin="round"
                              stroke-width="0.6"
                              d="M17.7 9l-5.4 5.7-1.6-1.6-1.5 1.6 3.1 3.2 7-7.3z"
                            ></path>
                            <ellipse
                              cx="13.7"
                              cy="14"
                              rx="13.4"
                              ry="14"
                            ></ellipse>
                          </g>
                          <ellipse
                            cx="102.5"
                            cy="131"
                            fill="#E0E7ED"
                            rx="30.5"
                            ry="2"
                          ></ellipse>
                        </g>
                      </svg>
                      <p className="sds-u-fs--small sds-u-m_top--large">
                        You’re off the hook, no new notifications.
                      </p>
                    </div>








                    ) : <div style={{padding: '1.4em'}}>{mappedNotifications}</div> }
















                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
